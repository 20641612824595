import React, { useContext, useEffect, useRef, useState } from "react";
import { Link as ReactLink, useLocation, useNavigate, useParams } from "react-router-dom";
import AppContext from "../../AppContext";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import MuiButton from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import AddHomeOutlinedIcon from "@mui/icons-material/AddHome";
import EventIcon from "@mui/icons-material/Event";
import EllipsisHorizontalIcon from "@heroicons/react/24/outline/EllipsisHorizontalIcon";
import SearchIcon from "@mui/icons-material/Search";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import { AvatarLink } from "../basic/AvatarLink";
import { Button } from "../basic/Button";
import { Logo } from "../basic/Logo";
import { SearchTextField } from "../basic/SearchTextField";
import Search from "@mui/icons-material/Search";
import { ScreenAppBar } from "./ScreenAppBar";
import { FooterAppBar } from "./FooterAppBar";
import { AppDialog } from "../complex/AppDialog";
import { ResponsiveToaster } from "../basic/ResponsiveToaster.jsx";
import MessagesIconSubbed from "../complex/MessagesIconSubbed";
import NotificationsIconSubbed from "../complex/NotificationsIconSubbed";
import PropTypes from "prop-types";
import { CircularProgress, Stack, SvgIcon } from "@mui/material";

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const LogoBox = styled(Box)(({ theme }) => ({
  width: 32,
  [theme.breakpoints.up("md")]: {
    width: 32,
  },
  [theme.breakpoints.down("sm")]: {
    width: 32,
  },
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  height: 64,
  padding: theme.spacing(1, 1),
  // paddingBottom: 0
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  position: "sticky",
  top: "0px",
  height: "100vh",
  "&> div": {
    border: "none",
    position: "relative",
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),

  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const ReponsivePaddingBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const ResponsiveMainBox = styled(Box)(({ theme }) => ({
  borderLeft: "solid 1px rgba(0,0,0,0.1)",
  borderRight: "solid 1px rgba(0,0,0,0.1)",
  marginLeft: 32,
  marginRight: 32,
  [theme.breakpoints.down("lg")]: {
    marginLeft: 0,
    marginRight: 32,
  },
  [theme.breakpoints.up("sm")]: {
    width: 600,
  },
  [theme.breakpoints.down("sm")]: {
    width: "calc(100% - 2px)",
    margin: "0 auto",
    // paddingTop: 64,
    // paddingBottom: 64,
  },
}));

const LayoutBox = styled(Box)(({ theme }) => ({
  // background: '#f6f7f9',
  minHeight: "100vh",
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    margin: "0 auto",
  },
}));

const ResponsiveFooterAppBar = styled(FooterAppBar)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

const ResponsiveScreenAppBar = styled(ScreenAppBar)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

const SidebarStack = styled(Stack)(({ theme }) => ({
  background: "rgb(247, 249, 249)",
}));

const PostBox = styled(Box)({
  overflow: "hidden",
});

const PostBoxButton = styled(MuiButton)(({ theme }) => ({
  fontSize: "1.2em",
  textTransform: "none",
  color: "black",
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

/**
 * Primary UI component for user interaction
 */
export const ResponsiveLayoutSidebar = ({ children, ...props }) => {
  const homeScreenRef = useRef(null);
  const location = useLocation();

  const tryScrollToTop = (e) => {
    if (e.currentTarget.getAttribute("href") === location.pathname) {
      e.preventDefault();
      if (location.pathname === "/") {
        p.listRef.scrollTo(0, 0);
      } else {
        window.scrollTo({
          top: 0,
          // behavior: 'smooth'
        });
      }
    }
  };

  var responsivePaddingBox;

  const params = useParams();
  const navigate = useNavigate();
  const [scroll, setScroll] = useState(0);
  const [open, setOpen] = useState(window.innerWidth <= 1200 ? false : true);
  const [postBoxOpen, setPostBoxOpen] = useState(false);
  const [toasterMessage, setToasterMessage] = useState({ message: "" });
  const [appDialog, setAppDialog] = useState({ open: false });
  const [globalState, setGlobalState, q, p, connectionStatus] =
    useContext(AppContext);

  const onClickCreateActivity = () => {
    setPostBoxOpen(false);
    navigate("/create-activity");
  };

  const onClickCreatePost = () => {
    setPostBoxOpen(false);
    navigate("/create-post");
  };

  const togglePostBox = () => {
    setPostBoxOpen(!postBoxOpen);
  };

  var timeoutId = useRef(null);
  const onSearchHandler = (event) => {
    const keyword = event.currentTarget.value;
    // Clear previous search invocation if called prior to timeout
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    timeoutId.current = setTimeout(async () => {
      p.searchFromSidebar = 1;
      setGlobalState({
        ...globalState,
        siteSearchKeywords: keyword,
      });
    }, [500]);
  };

  const onKeyPressNavigate = (event) => {
    if (event.key === "Enter") {
      navigate("/search");
    }
  };

  useEffect(() => {
    //if (p.responsiveLayoutScrollEventBinded !== true) {
    p.responsiveLayoutScrollEventBinded = true;
    console.log('params', params)
    if(params.id === "undefined") {
      navigate("/404")
    }

    window.addEventListener("scroll", function () {
      var scrollPos = window.scrollY;
      var invertedTop = -scrollPos + "px";
      // responsivePaddingBox.style.top = invertedTop;
      if (Math.abs(scrollPos) <= 700 / 3) {
        setScroll(invertedTop);
      }
    });

    function checkWindowSize() {
      if (window.innerWidth <= 1200) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    }

    // Attach the function to the resize event
    window.addEventListener("resize", checkWindowSize);

    // Call the function initially to check the window size on page load
    checkWindowSize();
    //}
  }, []);

  const childrenWithProps = React.Children.map(children, (child) => {
    // Check if the child is a valid React element
    if (React.isValidElement(child)) {
      console.log('child type:', child.type);
      console.log('child props:', child.props);

      // Check if this is the HomeScreen component
      const isHomeScreen = child.type.render && child.type.render.name === 'HomeScreen';

      console.log('isHomeScreen:', isHomeScreen);

      // Clone the child element and pass the ref if it's the HomeScreen component
      return React.cloneElement(child, {
        ref: isHomeScreen ? homeScreenRef : null,
        setToasterMessage,
        appDialog,
        setAppDialog,
        isHomeScreen: isHomeScreen // Add this prop for additional verification
      });
    }
    return child;
  });

  return (
    <ThemeProvider theme={theme}>
      <ResponsiveScreenAppBar />

      <LayoutBox
        maxWidth={"xl"}
        sx={{ display: "flex", margin: "0 auto" }}
        justifyContent={"center"}
      >
        <Drawer variant="permanent" open={open}>
          <DrawerHeader sx={{ justifyContent: "flex-start" }}>
            <Box ml={open ? 1 : 0}>
              <Logo linkToHome={true} color="color" size={48} />
            </Box>
          </DrawerHeader>

          <Stack
            direction={"column"}
            justifyContent={"space-between"}
            sx={{ height: "100%" }}
          >
            <List sx={{ padding: 0 }}>
              {[
                { text: "Home", path: "/" },
                { text: "Contacts", path: "/contacts" },
                { text: "Messages", path: "/messages" },
                { text: "Notifications", path: "/notifications" },
                { text: "Search", path: "/search" },
              ].map((textObj, index) => (
                <ListItem
                  key={textObj?.text}
                  disablePadding
                  sx={{ display: "block", marginBottom: theme.spacing(1) }}
                >
                  <ListItemButton
                    component={ReactLink}
                    onClick={tryScrollToTop}
                    to={`${textObj?.path}`}
                    sx={{
                      borderRadius: 8,
                      minHeight: 48,
                      justifyContent: "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color: "black",
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {index === 0 && <HomeOutlinedIcon />}
                      {index === 1 && <GroupOutlinedIcon />}
                      {index === 2 && <MessagesIconSubbed />}
                      {index === 3 && <NotificationsIconSubbed />}
                      {index === 4 && <SearchIcon />}
                    </ListItemIcon>
                    {open && (
                      <ListItemText
                        primary={textObj?.text}
                        sx={{
                          opacity: 1,
                          color: "black",
                          "&>span": { fontSize: "1.2rem" },
                        }}
                      />
                    )}
                  </ListItemButton>
                </ListItem>
              ))}

              {["Create"].map((text, index) => (
                <>
                  <ListItem key={text} disablePadding sx={{ display: "block" }}>
                    <Button
                      onClick={togglePostBox}
                      sx={{
                        borderRadius: 8,
                        minHeight: 48,
                        minWidth: 48,
                        justifyContent: open ? "center" : "center",
                        width: { xs: 48, lg: "100%" },
                        ml: { xs: 1, lg: 0 },
                        // px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          // color: "#2D9CDB",
                          color: "white",
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {postBoxOpen ? <CloseIcon /> : <AddIcon />}
                      </ListItemIcon>
                      <ListItemText
                        primary={text}
                        sx={{
                          textAlign: "left",
                          display: { xs: "none", lg: "block" },
                          opacity: open ? 1 : 0,
                          // color: "#2D9CDB",
                          "&>span": { fontSize: "1.2rem" },
                        }}
                      />
                    </Button>
                  </ListItem>
                  {postBoxOpen &&
                    ["Activity", "Post"].map((text, i) => (
                      <ListItem
                        key={`li-${text}`}
                        disablePadding
                        sx={{ display: "block" }}
                      >
                        <ListItemButton
                          component={ReactLink}
                          // onClick={
                          //   i === 0 ? onClickCreateActivity : onClickCreatePost
                          // }
                          to={i === 0 ? "/create-activity" : "/create-post"}
                          sx={{
                            borderRadius: 8,
                            minHeight: 48,
                            justifyContent: "center",
                            px: 2.5,
                            // px: 2.5,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              color: "black",
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            {i === 0 ? <EventIcon /> : <AddHomeOutlinedIcon/>}
                          </ListItemIcon>
                          <ListItemText
                            primary={text}
                            sx={{
                              display: { xs: "none", lg: "block" },
                              opacity: open ? 1 : 0,
                              // color: "#2D9CDB",
                              "&>span": { fontSize: "1.2rem" },
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                </>
              ))}
            </List>

            <List>
              {globalState?.userData?.dynamoDB?.givenName ? (
                //1>2 ?
                <ListItem
                  disablePadding
                  sx={{ display: "block", marginBottom: theme.spacing(1) }}
                >
                  <ListItemButton
                    component={ReactLink}
                    to={`/profile/${globalState?.userData?.dynamoDB?.id}`}
                    sx={{
                      borderRadius: 8,
                      minHeight: 48,
                      justifyContent: "center",
                      px: 2.5,
                    }}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      spacing={1}
                      flexGrow={1}
                      avatarText={
                        <>
                          {`${globalState?.userData?.dynamoDB?.givenName} ${globalState?.userData?.dynamoDB?.familyName}`}
                        </>
                      }
                    >
                      <AvatarLink
                        to={`/profile/${globalState?.userData?.dynamoDB?.id}`}
                        src={
                          globalState?.userData?.dynamoDB?.images
                            ?.items?.[0]?.url
                        }
                        sx={{ width: 42, height: 42 }}
                      >
                        {globalState?.userData?.dynamoDB?.givenName?.[0]}
                      </AvatarLink>
                      <Stack
                        sx={{ display: { xs: "none", lg: "block" } }}
                        direction={"column"}
                        flexGrow={1}
                      >
                        <Typography variant="body2">
                          <strong>
                            <>
                              {`${globalState?.userData?.dynamoDB?.givenName} ${globalState?.userData?.dynamoDB?.familyName}`}
                            </>
                          </strong>
                        </Typography>
                        <Typography variant="caption">View Profile</Typography>
                        {/* <Typography variant="caption">
                          @
                          {`${globalState?.userData?.dynamoDB?.givenName
                            .toLowerCase()
                            .trim()}${globalState?.userData?.dynamoDB?.familyName
                            .toLowerCase()
                            .trim()}`}
                        </Typography> */}
                      </Stack>

                      <SvgIcon sx={{ display: { xs: "none", lg: "block" } }}>
                        <EllipsisHorizontalIcon />
                      </SvgIcon>
                    </Stack>
                  </ListItemButton>
                </ListItem>
              ) : (
                <Stack
                  mb={2}
                  direction={"row"}
                  spacing={1}
                  alignItems={"center"}
                  sx={{ ml: { xs: 1, lg: 0 }, px: { xs: 2.5, sm: 0, lg: 2.5 } }}
                >
                  <Skeleton
                    variant="circular"
                    width={42}
                    height={42}
                  ></Skeleton>
                  <Stack
                    sx={{ display: { xs: "none", lg: "block" } }}
                    direction={"column"}
                    spacing={1}
                  >
                    <Skeleton
                      variant="rounded"
                      width={120}
                      height={12}
                    ></Skeleton>
                    <Skeleton
                      variant="rounded"
                      width={80}
                      height={10}
                    ></Skeleton>
                  </Stack>
                </Stack>
              )}
            </List>
          </Stack>
        </Drawer>

        <ResponsiveMainBox
          sx={{
            background: props.bg || "#ffffff",
          }}
          component="main"
          pt={
            props.pt !== undefined
              ? props.pt
              : {
                  xs: 8,
                  sm: 4,
                  md: 2,
                }
          }
          pb={
            props.pb !== undefined
              ? props.pb
              : {
                  xs: 8,
                  md: 2,
                }
          }
        >
          {
            // If component requires authentication
            props.waitForUserData ? (
              globalState?.userData?.userId ? (
                childrenWithProps
              ) : (
                <Stack my={6} alignItems={"center"}>
                  <CircularProgress sx={{ color: "#2D9CDB" }} />
                </Stack>
              )
            ) : (
              // Else if no authentication required
              childrenWithProps
            )
          }
          {/* <HomeScreen {...props} /> */}

          <Box
            id="toaster-container"
            sx={{
              position: "fixed",
              width: "inherit",
              height: 105,
              bottom: globalState?.toaster?.bottom || 0,
              zIndex: 999999,
              display: `${globalState?.toaster?.open ? "block" : "none"}`,
            }}
          >
            <ResponsiveToaster
              sx={{ position: "sticky" }}
              severity={globalState?.toaster?.type}
              open={globalState?.toaster?.open}
              toasterMessage={globalState?.toaster?.message}
              setToasterMessage={() =>
                setGlobalState({
                  ...globalState,
                  toaster: {
                    ...globalState?.toaster,
                    open: false,
                  },
                })
              }
            />
            <AppDialog
              open={appDialog.open}
              buttonDisabled={appDialog.buttonDisabled}
              maxButtonWidth={275}
              confirmText={appDialog.confirmText}
              onConfirm={() => {
                appDialog.onConfirm();
                setAppDialog({ ...appDialog, open: false });
              }}
              onClose={() => setAppDialog({ ...appDialog, open: false })}
            >
              {appDialog.dialogChildren}
            </AppDialog>
          </Box>
        </ResponsiveMainBox>

        <ReponsivePaddingBox
          ref={(thisElem) => (responsivePaddingBox = thisElem)}
          sx={{ position: "relative" }}
          my={2}
          mr={4}
        >
          <Box sx={{ position: "sticky", maxWidth: 350, top: scroll }}>
            {props.searchBarInSidebar !== false && (
              <Box mb={2}>
                <SearchTextField
                  sx={{ width: "100%" }}
                  placeholder="Search Sociogram"
                  onChange={onSearchHandler}
                  onKeyUp={onKeyPressNavigate}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => navigate("/search")}
                          aria-label="Search"
                          edge="end"
                        >
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            )}

            <SidebarStack py={1} mb={2}>
              <Stack
                mb={2}
                px={2}
                direction={"row"}
                justifyContent={"space-between"}
              >
                <Typography variant="h6" color="textPrimary">
                  <strong>Suggested for you</strong>
                </Typography>
              </Stack>

              <List sx={{ padding: 0 }}>
                {[
                  [
                    "Well being",
                    "Web3 Networking for Women - Cocktail & Dinner",
                  ],
                  ["Adventure", "Practice Public Speaking #1"],
                  ["Traveling", "Dubai Friendly Networking"],
                  ["Games", "Latte Lowdown"],
                ].map((obj, i) => (
                  <ListItem
                    key={`trending-item-${i}`}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        px: 2,
                      }}
                    >
                      <Stack direction="column">
                        <Typography variant="body2" color="textSecondary">
                          {obj[0]} · Trending
                        </Typography>
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          fontWeight={"bold"}
                        >
                          {obj[1]}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {Math.ceil((Math.random() * 100) / 10)} activities
                        </Typography>
                      </Stack>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </SidebarStack>

            <SidebarStack py={1} mb={2}>
              <Stack
                mb={2}
                px={2}
                direction={"row"}
                justifyContent={"space-between"}
              >
                <Typography variant="h6" color="textPrimary">
                  <strong>Get connected</strong>
                </Typography>
              </Stack>

              <List sx={{ padding: 0 }}>
                {[
                  [
                    "Tarik Đikić",
                    "https://cdn.qa.sociogram.com/app/features/user/profile/photos/1c5d5eca-a373-4741-b195-8f821361ba61.jpeg",
                  ],
                  [
                    "Omer Karasalihovic",
                    "https://cdn.qa.sociogram.com/app/features/user/profile/photos/563eefdc-8947-4883-89e0-7f1daef82b8a.jpeg",
                  ],
                ].map((itemObj, i) => (
                  <ListItem
                    key={`trending-item-${i}`}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        px: 2,
                      }}
                    >
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems={"center"}
                        sx={{ width: "100%" }}
                      >
                        <AvatarLink src={itemObj[1]}>{itemObj[0]}</AvatarLink>
                        <Typography sx={{ flexGrow: 1 }}>
                          {itemObj[0]}
                        </Typography>
                        <Button size="small">Follow</Button>
                      </Stack>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </SidebarStack>

            <Stack>
              <Stack mb={2}>
                <Typography variant="caption" color="textSecondary">
                  About · Help · Press · API · Jobs · Privacy · Terms ·
                  Locations · Language · Sociogram Verified
                </Typography>
              </Stack>

              <Stack>
                <Typography variant="caption" color="textSecondary">
                  &copy; {new Date().getFullYear()} SOCIOGRAM
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </ReponsivePaddingBox>
      </LayoutBox>
      <ResponsiveFooterAppBar />
    </ThemeProvider>
  );
};

ResponsiveLayoutSidebar.propTypes = {};

ResponsiveLayoutSidebar.defaultProps = {
  color: "primary",
};
