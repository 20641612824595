import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useBeforeUnload,
  useBlocker,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import AppContext from "../../AppContext";
import {
  Box,
  Button as MuiButton,
  CircularProgress,
  Container,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Button } from "../basic/Button";
import { ScreenNavHeader } from "../complex/ScreenNavHeader";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import { OnboardingScreenResponsiveLayout } from "../complex/OnboardingScreenResponsiveLayout";
import { UploaderButtonActivity } from "../complex/UploaderButtonActivity";
import PhotoGrid from "../complex/PhotoGrid.jsx";
import updateImageOfUser from "./queries/updateImageOfUser";
import uploadImages from "./sharedFunctions/uploadImages";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import transitions from "./transitions.json";
import { generateClient } from "aws-amplify/api";
const client = generateClient();

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const acceptableFiles = ["jpeg", "jpg", "png"];

/**
 * Primary UI component for user interaction
 */
export const UpdatePhotosScreen = ({ ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [globalState, setGlobalState, q, p] = useContext(AppContext);
  const [state, setState] = useState({
    disabled: false,
    loading: false,
    blockState: false,
    changes: 0,
    submission: {
      ...p.submission,
    },
  });

  const localP = useRef({ submission: p.submission });

  useBlocker(() => {
    if (
      state.changes === 0 ||
      localP.current.submission.done === true ||
      p.journey === "create-activity"
    ) {
      return false;
    } else {
      const stay = window.confirm(
        "Updates not complete. Changes might not be saved!"
      );

      if (!stay) {
      }
    }
  }, state.blockState);

  useEffect(() => {
    p.notificationsFetchLock = 1;
    p.prevPage = p.currentPage;
    if (!p.prevPage || !p.journey) navigate(-1);

    if (p.prevPage === "create-activity") {
      localP.current.submission = {
        ...p.submission,
        imagesData: p.submission?.imagesData,
        files: p.submission?.imagesData,
      };
      setState({
        ...state,
        changes: 0,
        submission: {
          ...state.submission,
          ...localP.current.submission,
        },
      });

      p.prevPage = p.currentPage || "edit-photos";
      p.currentPage = "edit-photos";
    } else if (p.prevPage === "edit-activity") {
      localP.current.submission = {
        ...p.submission,
        imagesData: p.submission?.imagesData,
        files: p.submission?.imagesData,
      };
      setState({
        ...state,
        changes: 0,
        submission: {
          ...state.submission,
          ...localP.current.submission,
        },
      });

      p.prevPage = p.currentPage || "edit-photos";
      p.currentPage = "edit-photos";
    } else if (p.prevPage === "edit-profile") {
      localP.current.submission = {
        ...p.submission,
        imagesData:
          p?.submission?.imagesData || p.userData?.dynamoDB?.images?.items,
        files: p?.submission?.imagesData || p.userData?.dynamoDB?.images?.items,
      };

      setState({
        ...state,
        changes: 0,
        submission: {
          ...state.submission,
          ...localP.current.submission,
        },
      });

      p.prevPage = p.currentPage || "edit-photos";
      p.currentPage = "edit-photos";
    } else {
      // Do nothing when creating a new activity
      p.prevPage = p.currentPage || "add-photos";
      p.currentPage = "add-photos";
    }
  }, []);

  const onRemoveImageHandler = (event) => {
    var index = event.currentTarget?.getAttribute("index");
    event.stopPropagation();
    props.setAppDialog({
      ...props.appDialog,
      confirmText: "Delete",
      dialogChildren: (
        <Stack px={3}>
          <Typography variant="body1">
            Are you sure you want to delete this photo?
          </Typography>
        </Stack>
      ),
      open: true,
      onConfirm: async () => {
        const newImages = localP.current.submission?.imagesData
          ? [...localP.current.submission?.imagesData]
          : [];

        const newFiles = localP.current.submission?.files
          ? [...localP.current.submission?.files]
          : [];

        if (p.prevPage === "edit-activity" || p.prevPage === "edit-profile") {
          q.updateState(globalState);
          await q.doQuery(
            "deleteImage",
            "Image(s) deleted.",
            "An error occured",
            {}, // updates to globalState
            p,
            newImages[index].id //arguments for graphql query
          );
        }

        newImages.splice(index, 1);
        newFiles.splice(index, 1);

        localP.current.submission = {
          ...localP.current.submission,
          imagesData: newImages.length > 0 ? newImages : [],
          files: newFiles.length > 0 ? newFiles : [],
        };

        p.submission = localP.current.submission;

        setState({
          ...state,
          blockState: false,
          submission: p.submission,
        });
        // setGlobalState({
        //   ...globalState,
        //   currentUserData: {
        //     ...localP.current.submission,
        //   },
        //   // ...p,
        // });
      },
    });
  };

  const onReorderImages = (newImagesData, newFilesData) => {
    localP.current.submission = {
      ...localP.current.submission,
      imagesData: newImagesData,
      files: newFilesData,
    };

    setState({
      ...state,
      changes: state.changes + 1,
      blockState: true,
      submission: localP.current.submission,
    });
  };

  const eventFlag = useRef(0);
  const attachPhotoHandler = async (event) => {
    setState({
      ...state,
      loading: true,
    });
    var cancelFlag = 0;

    if (event._reactName === "onInput" && eventFlag.current === 1) return;
    if (event._reactName === "onInput") eventFlag.current = 1;

    const attachedFiles = Array.from(event.target?.files);

    if (
      attachedFiles.length + (localP.current.submission?.files?.length || 0) >
      10
    ) {
      alert("Too many attachments. Max attachments is 10.");
      return;
    }

    if (attachedFiles) {
      // 1. Check file extensions
      attachedFiles.forEach((v, i) => {
        const file = v;
        const slashIndex = file.type.lastIndexOf("/");
        const fileExtension = file.type.substring(slashIndex + 1).toLowerCase();

        if (!acceptableFiles.includes(fileExtension) && cancelFlag === 0) {
          alert("This file format is not allowed.");
          cancelFlag = 1;
          return;
        }
      });
    }

    if (event?.target?.files && cancelFlag === 0) {
      const imagesData = [];

      Array.from(event.target.files).forEach((file, i) => {
        // const file = event.target.files[0];
        const previewURL = URL.createObjectURL(file);
        imagesData.push({
          label: file.name,
          url: previewURL,
        });
      });

      if (attachedFiles) {
        localP.current.submission = {
          ...localP.current.submission,
          imagesData: localP.current.submission?.imagesData
            ? [...localP.current.submission?.imagesData, ...imagesData]
            : [...imagesData],
          files: localP.current.submission?.files
            ? [...localP.current.submission?.files, ...event?.target?.files]
            : [...event?.target?.files],
        };
        setState({
          ...state,
          changes: state.changes + 1,
          blockState: true,
          submission: localP.current.submission,
        });

        // Upload files for specified routes
        if (p.prevPage === "edit-profile") {
          await uploadImages(
            "PROFILE",
            attachedFiles,
            p?.userData?.userId,
            "user/profile",
            //Callback for when images are done uploading
            () => {},
            (uploadProgress) => {}
          );
        } else if (p.journey === "edit-activity") {
          await uploadImages(
            "ACTIVITY",
            attachedFiles,
            localP.current.submission?.activityID,
            "activity",
            //Callback for when images are done uploading
            () => {},
            (uploadProgress) => {}
          );
        } else if (p.journey === "create-activity") {
          // Images are uploaded at /activity-privacy screen
        }
      }
    }
  };

  const confirmImageHandler = async () => {
    if (p.journey === "onboarding") {
      localP.current.submission = {
        ...localP.current.submission,
        imagesData: localP.current.submission?.imagesData,
        files: localP.current.submission?.files,
        done: true,
      };
      navigate("/profile-information/bio");
    } else {
      if (localP.current.submission?.imagesData) {
        switch (p.prevPage) {
          /*
           * ------------------Route
           */
          case "edit-activity":
          case "edit-profile":

            p.mutexCopy = p.mutex;
            p.validatedCopy = p.validated;

            const queryString = `mutation MyMutation {
              ${localP.current.submission?.imagesData
                .map(
                  (obj, i) =>
                    `m${i}: updateImageOfUser(input: {id: "${obj.id}", index: ${i}}) {id}`
                )
                .join("\r\n\t")}
            }`;

            q.updateState(globalState);
            const res_updatedImages = await q.doQuery(
              "updateImageOfUser",
              "Images updated",
              "An error occured",
              p.journey === "edit-profile"
                ? {}
                : {
                    submission: {
                      ...localP.current.submission,
                      imagesData: localP.current.submission?.imagesData,
                    },
                  },
              p,
              queryString
            );

            localP.current.submission.done = true;

            // p.submissionCopy is created because p.submission
            // will be reset with q.doQuery(). After the setGlobalState() 
            // call in this block, p.submission will be assigned the content
            // of p.submissionCopy
            p.submissionCopy = {
              ...localP.current.submission,
              ...p.submission,
              imagesData: localP.current.submission?.imagesData,
              files: localP.current.submission?.files,
            };

            q.updateState(globalState);
            const res_updatedProfile = await q.doQuery(
              "getUserAfterProfileUpdate",
              "Profile updated",
              "An error occured",
              p.journey === "edit-profile"
                ? {  }
                : {
                    submission: {
                      ...localP.current.submission,
                      imagesData: localP.current.submission?.imagesData,
                    },
                  },
              p,
              p
            );

            setGlobalState({
              ...globalState,
              userData: res_updatedProfile?.userData
            });

            // p.submission is reassigned here specifically after q.doQuery
            p.submission = p.submissionCopy;
            p.mutex = p.mutexCopy;
            p.validated = true;

            navigate(-1)
            break;

          /*
           * ------------------Route
           */
          case "create-activity":
            p.submission = {
              ...localP.current.submission,
              imagesData: localP.current.submission?.imagesData,
              files: localP.current.submission?.files,
            };
            navigate(transitions[location.pathname].confirm);
            break;

          default:
            break;
        }
      } else {
        navigate(-1);
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={"sm"} sx={{ minHeight: props.minHeight }}>
        <Box mb={4}>
          <ScreenNavHeader title="Update Photos">
            <Button
              disabled={globalState?.queryLoading}
              onClick={confirmImageHandler}
              size={"large"}
            >
              {globalState?.queryLoading && (
                <CircularProgress
                  size={18}
                  sx={{
                    mr: 1,
                    ml: -0.5,
                    color: "rgba(0,0,0,0.25)",
                  }}
                />
              )}
              Confirm
            </Button>
          </ScreenNavHeader>
        </Box>
        <Box mb={4}>
          <Typography>
            You can add up to 10 images to the activity page. Images are a great
            way to attract participants. Choose wisely.
          </Typography>
        </Box>
        <Stack justifyContent="center" alignItems="center">
          {/* <Grid container spacing={2}> */}
          <PhotoGrid
            onRemoveImage={onRemoveImageHandler}
            imagesData={state.submission?.imagesData}
            files={state.submission?.files}
            callback={onReorderImages}
          >
            <MuiButton
              component="label"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#F1F8F9",
                height: { xs: 150 * 1.0, sm: 150 },
                width: { xs: 150 * 1.0, sm: 150 },
                border: "dashed 2px #B9DEF3",
                borderRadius: "8px",
                textAlign: "center",
                textTransform: "none",
              }}
            >
              <Stack direction={"column"} spacing={1} alignItems={"center"}>
                <SvgIcon sx={{ height: 48, width: 48, color: "#132B39" }}>
                  <AddPhotoAlternateIcon />
                </SvgIcon>
                <Typography
                  sx={{ color: "#132B39" }}
                  variant="h6"
                  fontWeight={"bold"}
                >
                  Add Photo
                </Typography>
              </Stack>
              <VisuallyHiddenInput
                type="file"
                multiple
                onChange={attachPhotoHandler}
              />
            </MuiButton>
          </PhotoGrid>
        </Stack>
      </Container>
    </ThemeProvider>
  );
};

export const UpdatePhotosScreenLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar waitForUserData={props.waitForUserData}>
      <UpdatePhotosScreen {...props} />
    </ResponsiveLayoutSidebar>
  );
};

export const UpdatePhotosScreenOnboardingLayout = (props) => {
  return (
    <OnboardingScreenResponsiveLayout
      reponsivePositionBoxSx={{
        position: "static",
        top: "initial",
        left: "initial",
        transform: "translate(0,0) !important",
      }}
      hideLogo={true}
    >
      <UpdatePhotosScreen minHeight={"600px"} {...props} />
    </OnboardingScreenResponsiveLayout>
  );
};

UpdatePhotosScreen.propTypes = {};

UpdatePhotosScreen.defaultProps = {
  color: "primary",
};
