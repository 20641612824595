import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Link as ReactLink,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import AppContext from "../../AppContext";
import {
  Avatar,
  Alert,
  AvatarGroup,
  Box,
  Button as MuiButton,
  Container,
  Divider,
  IconButton,
  InputBase,
  Link,
  Rating,
  Skeleton,
  Stack,
  Switch as MuiSwitch,
  SvgIcon,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import { LinkAttachment } from "../basic/LinkAttachment.jsx";
import { Button } from "../basic/Button";
import { CategoryChip } from "../basic/CategoryChip";
import { AvatarLink } from "../basic/AvatarLink";
import { ScreenNavHeader } from "../complex/ScreenNavHeader";
import { JoinButton } from "../complex/JoinButton";
import { EmbeddedContent } from "../basic/EmbeddedContent.jsx";
import LinkInsert from "../complex/LinkInsert";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import GroupIcon from "@mui/icons-material/Group";
import ImageCarousel from "../complex/ImageCarousel.jsx";
import LinkIcon from "@mui/icons-material/Link";
import SendIcon from "@mui/icons-material/Send";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import TranslateIcon from "@mui/icons-material/Translate";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import getActivity from "./queries/getActivity.js";
import listLanguages from "./queries/listLanguages";
import moment from "moment";
import monthmap from "../utils/monthmap.js";
import copyToClipboard from "../utils/copyToClipboard.js";
import timestampdifference from "../utils/timestampdifference";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";

import { generateClient, post } from "aws-amplify/api";
const client = generateClient();

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

function ensureProtocolAndSubdomain(url) {
  // Check if URL has a protocol
  if (!/^https?:\/\//i.test(url)) {
    // If no protocol, add http:// as default
    url = "http://" + url;
  }

  try {
    // Create a URL object from the input URL
    const parsedUrl = new URL(url);

    // Check if the protocol is either 'http:' or 'https:'
    const hasValidProtocol =
      parsedUrl.protocol === "http:" || parsedUrl.protocol === "https:";

    // Split the hostname into parts
    const hostnameParts = parsedUrl.hostname.split(".");

    // Check if there are at least three parts (subdomain.domain.tld)
    const hasSubdomain = hostnameParts.length > 2;

    return {
      url: parsedUrl.href, // Return the URL with protocol if it was missing
      hasValidProtocol,
      hasSubdomain,
    };
  } catch (e) {
    // URL constructor throws an error for invalid URLs
    return {
      url: null,
      hasValidProtocol: false,
      hasSubdomain: false,
    };
  }
}

const paymentCasingMap = {
  "CASH ONSITE": "Cash Onsite",
  "CARD ONSITE": "Card Onsite",
  ONLINE: "Online",
};

function capitalizeWords(sentence) {
  if (!sentence) return;
  return sentence
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
}

const UserDecisionButtons = ({
  localState,
  globalState,
  onLeaveActivity,
  onAcceptInvite,
  fullWidth = false,
}) => {
  return localState?.attendanceObj?.status === "CONFIRMED" ? (
    <Button
      id={localState.attendanceObj?.id}
      onClick={onLeaveActivity}
      fullWidth={fullWidth}
      disabled={
        globalState?.queryLoading ||
        !globalState?.initialized ||
        moment(localState.activityStart) < moment(Date.now())
      }
      color="error"
    >
      Leave
    </Button>
  ) : (
    <Stack direction={fullWidth === true ? "column" : "row"} spacing={1}>
      <Button
        id={localState.attendanceObj?.id}
        onClick={onAcceptInvite}
        disabled={
          globalState?.queryLoading ||
          !globalState?.initialized ||
          moment(localState.activityStart) < moment(Date.now()) ||
          localState.activityAttendance?.items?.length >=
            localState.numberOfParticipants
        }
      >
        Accept
      </Button>
      <Button
        id={localState.attendanceObj?.id}
        onClick={onLeaveActivity}
        color="error"
        disabled={
          globalState?.queryLoading ||
          !globalState?.initialized ||
          moment(localState.activityStart) < moment(Date.now()) ||
          localState.activityAttendance?.items?.length >=
            localState.numberOfParticipants
        }
      >
        Decline
      </Button>
    </Stack>
  );
};

/**
 * Primary UI component for user interaction
 */
export const ViewActivityScreen = ({ ...props }) => {
  const navigate = useNavigate();
  const [globalState, setGlobalState, q, p] = useContext(AppContext);

  const [state, setState] = React.useState({
    loading: true,
    ...p.previousViewedActivityState,
  });

  const location = useLocation();
  const params = useParams();

  const privacyMap = {
    PUBLIC: "Public",
    JOIN_BY_REQUEST: "Join By Request",
    PRIVATE: "Private",
  };

  useEffect(() => {
    (async () => {
      p.prevPage = p.currentPage || "view-activity";
      p.currentPage = "view-activity";
      p.journey = "view-activity";

      if (p.prevPage === "view-photo") {
        setState({
          loading: false,
          ...p.previousViewedActivityState,
        });
        return;
      }

      try {
        const res = await client.graphql({
          query: getActivity(params.id),
        });
        const languages_res = await client.graphql({
          query: listLanguages(),
        });

        p.previousViewedActivityState = {
          ...res.data.getActivity,
          languages: languages_res?.data?.listLanguages?.items?.filter((obj) =>
            res.data?.getActivity?.languageIDS?.includes(obj.id)
          ),
          activityAttendance: {
            items: res.data?.getActivity?.activityAttendance?.items?.filter(
              (obj) => obj.status === "CONFIRMED"
            ),
          },
          attendanceObj: res.data?.getActivity?.activityAttendance?.items?.find(
            (obj) => obj?.user?.id === globalState?.userData?.userId
          ),
        };

        console.log(
          "activityAttendance",
          res.data?.getActivity?.activityAttendance?.items
        );

        setState({
          ...state,
          loading: false,
          ...res.data.getActivity,
          languages: languages_res?.data?.listLanguages?.items?.filter((obj) =>
            res.data?.getActivity?.languageIDS?.includes(obj.id)
          ),
          activityAttendance: {
            items: res.data?.getActivity?.activityAttendance?.items?.filter(
              (obj) => obj.status === "CONFIRMED"
            ),
          },
          attendanceObj: res.data?.getActivity?.activityAttendance?.items?.find(
            (obj) => obj?.user?.id === globalState?.userData?.userId
          ),
        });

        p.journey = "viewing-activity";
      } catch (error) {
        console.error("Error fetching activity:", error);
        navigate("/404");
        // Handle the error appropriately, e.g., set an error state or show a message to the user
      }
    })();
  }, []);

  const onClickJoinEdit = async () => {
    if (globalState?.userData?.userId === state?.userObject?.id) {
    } else {
      // If user had confirmed before but they left (or were removed),
      // then update their previous attendance status
      var res;
      if (state.attendanceObj?.id) {
        if (state.privacy === "PUBLIC") {
          q.updateState(globalState);
          res = await q.doQuery(
            "updateActivityAttendanceStatus",
            "You have joined this event",
            "An error occured",
            {},
            p,
            state.attendanceObj?.id,
            "CONFIRMED"
          );
        } else if (state.privacy === "JOIN_BY_REQUEST") {
          q.updateState(globalState);
          res = await q.doQuery(
            "rejoinActivity",
            "You have requested to join this event",
            "An error occured",
            {},
            p,
            state.attendanceObj?.id,
            "CONFIRMED"
          );
        }

        setState({
          ...state,
          attendanceObj: res?.data?.updateActivityAttendanceStatus,
        });
      }
      // Otherwise, create a new attendance record
      else {
        if (state.privacy === "PUBLIC") {
          q.updateState(globalState);
          res = await q.doQuery(
            "createActivityRequest",
            "You have joined this event",
            "An error occured",
            {},
            p,
            state.id
          );
        } else if (state.privacy === "JOIN_BY_REQUEST") {
          q.updateState(globalState);
          res = await q.doQuery(
            "createActivityRequest",
            "You have requested to join this event",
            "An error occured",
            {},
            p,
            state.id
          );
        }

        const newAttendanceObject = {
          ...res?.data?.createActivityRequest,
        };

        newAttendanceObject.status = "CONFIRMED";

        setState({
          ...state,
          attendanceObj: newAttendanceObject,
        });
      }
    }
  };

  const onLeaveActivity = async () => {
    q.updateState(globalState);
    const res = await q.doQuery(
      "leaveActivity",
      "You have left this event",
      "An error occured",
      {},
      p,
      state.attendanceObj?.id,
      "CANCELED"
    );

    setState({
      ...state,
      attendanceObj: res?.data?.updateActivityAttendanceStatus,
    });
  };

  const onAcceptInvite = async () => {
    q.updateState(globalState);
    const res = await q.doQuery(
      "updateActivityAttendanceStatus",
      "You have accepted this activity invitation",
      "An error occured",
      {},
      p,
      state.attendanceObj?.id,
      "CONFIRMED"
    );

    setState({
      ...state,
      attendanceObj: res?.data?.updateActivityAttendanceStatus,
    });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteActivity = async () => {
    // Implement delete functionality here
    console.log("Delete activity");
    q.updateState(globalState);
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this activity?"
    );
    if (confirmDelete) {
      try {
        const res = await q.doQuery(
          "deleteActivity",
          "Activity deleted",
          "An error occurred",
          {},
          p,
          params.id
        );
        // Handle successful deletion
        console.log("Activity deleted successfully:", res);
        navigate("/");
      } catch (error) {
        console.error("Error deleting activity:", error);
        // Handle error (e.g., show error message to user)
      }
    }

    handleClose();
  };

  if (
    state.privacy === "PRIVATE" &&
    state.attendanceObj?.id !== globalState?.userData?.userId &&
    state.createdByID !== globalState?.userData?.userId &&
    !state?.attendanceObj?.status
  ) {
    return <>Not allowed</>;
  } else if (state.loading) {
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth="sm">
          <Box>
            <Box mb={4}>
              <ScreenNavHeader title="View Activity">
                <Button disabled={state.loading} size={"large"}>
                  View
                </Button>
              </ScreenNavHeader>
            </Box>

            <Stack>
              <Stack
                key={`skeleton-activity`}
                direction={"column"}
                spacing={1}
                mb={4}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing={2}
                  sx={{ width: "100%" }}
                >
                  <Skeleton
                    animation={"wave"}
                    variant="circular"
                    width={48}
                    height={48}
                  />
                  <Stack
                    direction={"row"}
                    spacing={1}
                    flexGrow={1}
                    justifyContent={"space-between"}
                  >
                    <Stack direction={"column"} spacing={1}>
                      <Skeleton variant="rounded" width={160} height={10} />
                      <Skeleton
                        variant="rounded"
                        width={100}
                        height={10}
                        sx={{ flexGrow: 1 }}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>

            <Box mb={2}>
              <Skeleton variant="rounded" sx={{ width: 200, height: 18 }} />
            </Box>
            <Stack mb={2} direction={"column"} spacing={1}>
              <Skeleton variant="rounded" sx={{ width: "100%", height: 14 }} />
              <Skeleton variant="rounded" sx={{ width: "90%", height: 14 }} />
            </Stack>
            <Box sx={{ display: "none" }}>
              <Stack mb={4} direction={"row"} spacing={2}>
                <Skeleton
                  variant="rounded"
                  sx={{ width: 165, height: 165 }}
                ></Skeleton>
                <Skeleton
                  variant="rounded"
                  sx={{ width: 165, height: 165 }}
                ></Skeleton>
              </Stack>

              <Stack mb={2} direction={"row"} spacing={2}>
                <Skeleton
                  variant="rounded"
                  sx={{
                    width: "50%",
                    height: 44,
                    borderRadius: 2,
                    p: 1,
                  }}
                ></Skeleton>
                <Skeleton
                  variant="rounded"
                  sx={{
                    width: "50%",
                    height: 44,
                    borderRadius: 2,
                    p: 1,
                  }}
                ></Skeleton>
              </Stack>

              <Stack direction={"row"} spacing={2}>
                <Skeleton
                  variant="rounded"
                  sx={{
                    width: "50%",
                    height: 44,
                    borderRadius: 2,
                    p: 1,
                  }}
                ></Skeleton>
                <Skeleton
                  variant="rounded"
                  sx={{
                    width: "50%",
                    height: 44,
                    borderRadius: 2,
                    p: 1,
                  }}
                ></Skeleton>
              </Stack>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth="sm">
          <Box>
            <Box mb={4}>
              <ScreenNavHeader title="View Activity">
                {globalState?.userData?.userId === state?.userObject?.id ? (
                  <>
                    <Button
                      disabled={
                        moment(state.activityStart) < moment(Date.now()) ||
                        globalState?.queryLoading ||
                        !globalState?.initialized
                      }
                      component={ReactLink}
                      to={`/edit-activity/${state?.id}`}
                    >
                      Edit
                    </Button>
                  </>
                ) : state?.attendanceObj?.status === "CONFIRMED" ||
                  state?.attendanceObj?.status === "INIT" ? (
                  <UserDecisionButtons
                    localState={state}
                    globalState={globalState}
                    onLeaveActivity={onLeaveActivity}
                    onAcceptInvite={onAcceptInvite}
                  />
                ) : (
                  <JoinButton
                    disabled={
                      globalState?.queryLoading ||
                      !globalState?.initialized ||
                      moment(state.activityStart) < moment(Date.now())
                    }
                    onClick={onClickJoinEdit}
                    state={state}
                    globalState={globalState}
                    color={
                      state?.attendanceObj?.status === "CONFIRMED" ||
                      state?.attendanceObj?.status === "INIT"
                        ? "error"
                        : "primary"
                    }
                  />
                )}
              </ScreenNavHeader>
            </Box>

            {moment(state.activityStart) <= moment(Date.now()) &&
              moment(state.activityEnd) >= moment(Date.now()) && (
                <Stack mb={4}>
                  <Alert severity="warning">
                    <>This activity has already started.</>
                  </Alert>
                </Stack>
              )}
            {moment(state.activityEnd) <= moment(Date.now()) && (
              <Stack mb={4}>
                <Alert severity="warning">
                  <>This activity has ended.</>
                </Alert>
              </Stack>
            )}

            <Stack
              direction="row"
              alignItems={"center"}
              spacing={2}
              sx={{ mb: 2, width: "100%" }}
            >
              <Stack sx={{ flexGrow: 1 }}>
                <AvatarLink
                  to={`/profile/${state.userObject?.id}`}
                  sx={{ width: 42, height: 42 }}
                  src={state.userObject?.images?.items?.[0]?.url}
                  avatarText={`${state.userObject?.givenName} ${state.userObject?.familyName}`}
                >
                  {state.userObject?.giveName?.[0]}
                </AvatarLink>
              </Stack>

              <Stack flexDirection={"row"} display="flex">
                <IconButton
                  // component={ReactLink}
                  // to="/invite"
                  disabled={
                    (state.activityAttendance?.items?.length >= state.numberOfParticipants) &&
                    state.createdByID === p.userData?.userId
                  }
                  onClick={() => {
                    return state.createdByID === p.userData?.userId
                      ? (() => {
                          p.createdActivityID = state.id;
                          navigate(`/invite`);
                        })()
                      : copyToClipboard(
                          `${process.env.REACT_APP_WEBSITE}/activity/${state.id}`,
                          () =>
                            setGlobalState({
                              ...globalState,
                              toaster: {
                                ...globalState?.toaster,
                                type: "info",
                                open: true,
                                bottom: 25,
                                message: `Share URL copied to clipboard: ${process.env.REACT_APP_WEBSITE}/activity/${state.id}`,
                              },
                            })
                        );
                  }}
                  size="medium"
                  sx={{
                    position: "relative",
                    right: 0,
                    alignSelf: "end",
                    marginTop: "-10px",
                  }}
                >
                  <SvgIcon sx={{ width: 18, height: 18 }}>
                    {state.createdByID === p.userData?.userId ? (
                      <PersonAddAlt1Icon />
                    ) : (
                      <SendIcon />
                    )}
                  </SvgIcon>
                </IconButton>

                {globalState?.userData?.userId === state?.userObject?.id && (
                  <>
                    <IconButton
                      size="medium"
                      sx={{
                        position: "relative",
                        right: 0,
                        alignSelf: "end",
                        marginTop: "-10px",
                      }}
                      onClick={(event) => setAnchorEl(event.currentTarget)}
                    >
                      <SvgIcon sx={{ width: 18, height: 18 }}>
                        <MoreVertIcon />
                      </SvgIcon>
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={() => setAnchorEl(null)}
                    >
                      <MenuItem
                        onClick={() => {
                          deleteActivity();
                          setAnchorEl(null);
                        }}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </>
                )}

                <Typography variant="subtitle2"></Typography>
              </Stack>
            </Stack>

            <Stack direction={"row"} justifyContent={"space-between"} mb={2}>
              <Typography variant="h5" fontWeight={700}>
                {state.name}
              </Typography>

              {(state.privacy === "JOIN_BY_REQUEST" ||
                state.privacy === "PRIVATE") && (
                <SvgIcon color="error" fontSize="medium">
                  <LockOutlinedIcon />
                </SvgIcon>
              )}
            </Stack>

            {/* {props.postTagUsers?.items.length > 0 && (
            <Stack mt={-1} mb={2} direction={"row"}>
              <Avatar
                src={
                  state.postTagUsers?.items?.[0]?.user?.images?.items[0]?.url
                }
                sx={{ width: 20, height: 20, mr: 0.5 }}
              />
              <Typography variant="body2">
                With{" "}
                <strong>
                  {props.postTagUsers?.items?.[0]?.user?.givenName}
                </strong>
              </Typography>
            </Stack>
          )} */}

            <Box mb={2}>
              <Typography sx={{ "word-break": "break-word" }}>
                {state.description}
              </Typography>
            </Box>

            {/* Activity Photos */}
            {state.images?.items?.length > 0 && (
              <Box my={2}>
                <ImageCarousel
                  centered
                  imagesData={state.images?.items?.map((obj) => {
                    return { ...obj };
                  })}
                />
              </Box>
            )}
            {/* Date and time */}
            {(() => {
              const startTimestamp = state
                ? new Date(state.activityStart)
                : null;
              const endTimestamp = state ? new Date(state.activityEnd) : null;
              var start = { sDay: 0, sMonth: 0, sHour: 0, sMinute: 0 };
              var end = { eDay: 0, eMonth: 0, eHour: 0, eMinute: 0 };

              if (startTimestamp) {
                start = {
                  sDay: startTimestamp.getDate(),
                  sMonth: startTimestamp.getMonth(),
                  sHour: String(startTimestamp.getHours()).padStart(2, "0"),
                  sMinute: String(startTimestamp.getMinutes()).padStart(2, "0"),
                };
              }
              if (endTimestamp) {
                end = {
                  eDay: endTimestamp.getDate(),
                  eMonth: endTimestamp.getMonth(),
                  eHour: String(endTimestamp.getHours()).padStart(2, "0"),
                  eMinute: String(endTimestamp.getMinutes()).padStart(2, "0"),
                };
              }

              const { sDay, sMonth, sHour, sMinute } = start;
              const { eDay, eMonth, eHour, eMinute } = end;

              return (
                <Stack
                  mb={2}
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      width: "50%",
                      border: "solid 2px #2D9CDB",
                      borderRadius: 2,
                      p: 1,
                    }}
                  >
                    <Stack
                      sx={{ margin: "0 auto" }}
                      justifyContent="center"
                      spacing={1}
                      direction="row"
                    >
                      <Typography>
                        <strong>{sDay}</strong>
                      </Typography>
                      <Typography sx={{ alignSelf: "end" }} variant="caption">
                        {monthmap[sMonth]}
                      </Typography>
                    </Stack>
                  </Box>

                  <Stack
                    direction="row"
                    alignItems="end"
                    spacing={1}
                    sx={{
                      width: "50%",
                      border: "solid 2px #dff0fa",
                      background: "#dff0fa",
                      borderRadius: 2,
                      p: 1,
                    }}
                  >
                    <Typography>
                      <strong>
                        {`${sHour}:${sMinute}`} - {`${eHour}:${eMinute}`}
                      </strong>
                    </Typography>
                    <Typography variant="caption">
                      {timestampdifference(new Date(), startTimestamp)}
                    </Typography>
                  </Stack>
                </Stack>
              );
            })()}

            {/* price and no. of attendees. */}
            <Stack mb={2} direction="row" alignItems="baseline">
              <Stack
                sx={{ width: "100%" }}
                justifyContent="space-between"
                pl={2}
                direction="row"
              >
                <Stack
                  mb={1}
                  spacing={1}
                  direction={"row"}
                  sx={{ width: "50%" }}
                >
                  <SvgIcon color="primary">
                    <GroupIcon />
                  </SvgIcon>
                  <Typography>
                    {state.activityAttendance?.items?.length}/
                    {state.numberOfParticipants}
                  </Typography>
                </Stack>

                <Stack
                  mb={1}
                  spacing={1}
                  direction={"row"}
                  sx={{ width: "50%" }}
                >
                  <SvgIcon color="success">
                    <AttachMoneyIcon />
                  </SvgIcon>
                  <Typography>
                    {state.currency !== "undefined" && state.currency}{" "}
                    {state.price
                      ? parseFloat(state.price).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "FREE"}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>

            {/* Payment Method */}
            {state.paymentMethod && (
              <Stack mb={4} direction={"column"} spacing={1}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <SvgIcon sx={{ color: "#1D1D1D" }}>
                    <AttachMoneyIcon />
                  </SvgIcon>
                  <Typography sx={{ color: "#1D1D1D" }}>
                    {state.paymentMethod?.toLowerCase() === "online"
                      ? "Pay the stated amount online"
                      : "Pay the stated amount upon arrival"}
                  </Typography>
                </Stack>
                {state.paymentMethod?.toLowerCase() === "online" && (
                  <Stack
                    spacing={1}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <SvgIcon sx={{ color: "rgb(25, 118, 210)" }}>
                      <LinkIcon />
                    </SvgIcon>
                    <Link
                      href={`${
                        ensureProtocolAndSubdomain(state.paymentLink).url
                      }`}
                      target="_blank"
                    >
                      <Typography>
                        {ensureProtocolAndSubdomain(state.paymentLink).url}
                      </Typography>
                    </Link>
                  </Stack>
                )}
              </Stack>
            )}

            {/* Activity Privacy */}
            <Stack mb={2} direction={"row"} justifyContent={"space-between"}>
              <Typography fontWeight={700}>Activity Privacy</Typography>
            </Stack>

            <Box mb={6}>{privacyMap[state.privacy]}</Box>

            {/* Activity Category */}
            <Stack mb={2} direction={"row"} justifyContent={"space-between"}>
              <Typography fontWeight={700}>Activity Category</Typography>
            </Stack>

            <Box mb={6}>
              <CategoryChip
                sx={{ marginRight: 1, marginBottom: 1 }}
                categoryName={`${state.interest?.parentInterest?.title} - ${state.interest?.title}`}
                label={`${state.interest?.parentInterest?.title} - ${state.interest?.title}`}
              />
            </Box>

            {/* Activity Location */}
            <Stack
              mb={2}
              alignItems={"center"}
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Typography fontWeight={700}>Activity Location</Typography>
            </Stack>

            {state.locationType === "PHYSICAL" ? (
              <Box mb={6}>
                {state?.address && (
                  <Typography variant="body1">{state?.address}</Typography>
                )}
                <iframe
                  width="100%"
                  height="200"
                  style={{ border: "0" }}
                  loading="lazy"
                  allowFullScreen
                  referrerPolicy="no-referrer-when-downgrade"
                  src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCK_7n9C93rb_5VmGoqAyTr-FlceCui28U
            &q=${state.latitude},${state.longitude}`}
                ></iframe>
              </Box>
            ) : (
              <Box mb={6}>
                {/* Link Preview */}
                {state.link?.url && (
                  <EmbeddedContent type="link" url={state.link.url} />
                )}
              </Box>
            )}

            {/* Address Description */}
            {state.addressLine && (
              <Stack mb={6} spacing={2}>
                <Typography gutterBottom fontWeight={700}>
                  Address Description
                </Typography>
                <Typography>{state.addressLine}</Typography>
              </Stack>
            )}

            {/* Conditions */}

            {state.conditions?.filter((str) => str)?.length > 0 && (
              <Box mb={6}>
                <Typography gutterBottom fontWeight={700}>
                  {" "}
                  Conditions
                </Typography>

                <ul
                  style={{
                    margin: 0,
                    padding: 0,
                    listStyle: "none",
                  }}
                >
                  {state.conditions
                    ?.filter((str) => str)
                    ?.map((v, i) => (
                      <li style={{ padding: `12px 0px` }}>
                        <Stack direction={"row"} spacing={1}>
                          <span
                            style={{
                              fontSize: "0.8em",
                              background: "rgba(12, 47, 67, 1)",
                              borderRadius: 4,
                              width: 16,
                              height: 16,
                              padding: 4,
                              display: "flex",
                              alignContent: "center",
                              justifyContent: "center",
                              color: "white",
                            }}
                          >
                            {i + 1}
                          </span>
                          <Typography>{v}</Typography>
                        </Stack>
                      </li>
                    ))}
                </ul>
              </Box>
            )}

            {/* Age Restrictions */}
            <Box mb={6}>
              <Typography gutterBottom fontWeight={700}>
                Age Restrictions
              </Typography>

              {state.fromAge ? (
                <Typography gutterBottom>
                  From {state.fromAge}-{state.toAge} years old
                </Typography>
              ) : (
                <Typography gutterBottom>None</Typography>
              )}
            </Box>

            {/* Languages */}
            {state.languages?.length > 0 && (
              <Box mb={6}>
                <Box pb={2}>
                  <Typography gutterBottom fontWeight={700}>
                    Languages
                  </Typography>
                </Box>

                <Stack direction={"row"} spacing={2}>
                  <SvgIcon>
                    <TranslateIcon />
                  </SvgIcon>
                  <Typography>
                    {state.languages?.map((obj, i) =>
                      `${obj.name}`.concat(
                        i < state.languages?.length - 1 ? `, ` : ""
                      )
                    )}
                  </Typography>
                </Stack>
              </Box>
            )}

            {/* No. of attendees */}
            <Stack
              mb={1}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack direction="row" alignItems={"center"} spacing={1}>
                <Box
                  sx={{
                    width: "52px",
                    height: "24px",
                    flexGrow: 0,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    padding: "2px 10px",
                    borderRadius: "100px",
                    backgroundColor: "#b9def3",
                    justifyContent: "center",
                  }}
                >
                  <Typography fontWeight={700} variant="body2">
                    {state.activityAttendance?.items?.length}/
                    {state.numberOfParticipants}
                  </Typography>
                </Box>
                <Typography>Attending</Typography>
              </Stack>
            </Stack>

            <Box mb={6} display={"flex"}>
              <AvatarGroup
                sx={{ position: "relative", left: -2 }}
                total={
                  state.activityAttendance?.items?.length >= 3
                    ? 3
                    : state.activityAttendance?.items?.length
                }
              >
                {state.activityAttendance?.items?.map((attendee, i) => {
                  return (
                    <AvatarLink
                      to={`/profile/${attendee?.user?.id}`}
                      key={`avatar-${i}`}
                      sx={{ width: 40, height: 40 }}
                      alt={attendee?.user?.givenName}
                      src={
                        attendee?.user?.images?.items?.[0] &&
                        attendee?.user?.images?.items?.[0]?.url
                      }
                    />
                  );
                })}
              </AvatarGroup>
            </Box>

            {globalState?.userData?.userId === state?.userObject?.id ? (
              <Button
                component={ReactLink}
                disabled={
                  moment(state.activityStart) < moment(Date.now()) ||
                  globalState?.queryLoading ||
                  !globalState?.initialized
                }
                to={`${process.env.REACT_APP_WEBSITE}/edit-activity/${state?.id}`}
                fullWidth
              >
                Edit
              </Button>
            ) : state?.attendanceObj?.status === "CONFIRMED" ||
              state?.attendanceObj?.status === "INIT" ? (
              <UserDecisionButtons
                fullWidth={true}
                localState={state}
                globalState={globalState}
                onLeaveActivity={onLeaveActivity}
                onAcceptInvite={onAcceptInvite}
              />
            ) : (
              <JoinButton
                disabled={
                  globalState?.queryLoading ||
                  !globalState?.initialized ||
                  moment(state.activityStart) < moment(Date.now())
                }
                onClick={onClickJoinEdit}
                state={state}
                fullWidth
                globalState={globalState}
              />
            )}
          </Box>
        </Container>
      </ThemeProvider>
    );
  }
};

export const ViewActivityScreenLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar waitForUserData={props.waitForUserData}>
      <ViewActivityScreen {...props} />
    </ResponsiveLayoutSidebar>
  );
};

ViewActivityScreen.propTypes = {
  paid: PropTypes.bool,
  currency: PropTypes.string,
  price: PropTypes.number,
};

ViewActivityScreen.defaultProps = {
  color: "primary",
};
