import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button as MuiButton,
  Container,
  IconButton,
  InputAdornment,
  Stack,
  SvgIcon,
  Typography,
  Skeleton,
  CircularProgress,
} from "@mui/material";
import { ActivityCard } from "./ActivityCard";
import timestampdifference from "../utils/timestampdifference";
// import RoomIcon from "@mui/icons-material/Room";
// import monthmap from "../utils/monthmap";

const ActivityCardContainer = (props) => {
  const navigate = useNavigate();
  const selectActivityHandler = (activityObj) => {
    return () => {
      navigate(`/activity/${activityObj.id}`);
    };
  };

  return (
    <>
      {props.loading &&
        [1, 2, 3, 4, 5, 6, 7, 8, 9].map((v, i) => (
          <Stack
            key={`skeleton-${i}`}
            mb={2}
            px={1}
            spacing={1}
            direction={"row"}
            justifyContent={"space-between"}
          >
            <Skeleton variant="rounded" sx={{ height: 300, width: '100%' }} />
          </Stack>
        ))}

      {!props.loading && props?.data?.length === 0 && (
        <Typography>No results found in Activities.</Typography>
      )}

      {!props.loading &&
        props?.data &&
        props?.data
          // ?.filter(
          //   (activityObj) =>
          //     activityObj.address && activityObj.address !== "undefined"
          // )
          .map((activityObj, i) => {
            const startTimestamp = activityObj
              ? new Date(activityObj.activityStart)
              : null;
            const endTimestamp = activityObj
              ? new Date(activityObj.activityEnd)
              : null;
            var start = { sDay: 0, sMonth: 0, sHour: 0, sMinute: 0 };
            var end = { eDay: 0, eMonth: 0, eHour: 0, eMinute: 0 };

            if (startTimestamp) {
              start = {
                sDay: startTimestamp.getDate(),
                sMonth: startTimestamp.getMonth(),
                sHour: String(startTimestamp.getHours()).padStart(2, "0"),
                sMinute: String(startTimestamp.getMinutes()).padStart(2, "0"),
              };
            }
            if (endTimestamp) {
              end = {
                eDay: endTimestamp.getDate(),
                eMonth: endTimestamp.getMonth(),
                eHour: String(endTimestamp.getHours()).padStart(2, "0"),
                eMinute: String(endTimestamp.getMinutes()).padStart(2, "0"),
              };
            }

            const { sDay, sMonth, sHour, sMinute } = start;
            const { eDay, eMonth, eHour, eMinute } = end;

            return (
              <Box mb={0}>
                <ActivityCard
                  {...activityObj}
                  id={activityObj.id}
                  category={activityObj.interest?.parentInterest?.title}
                  subcategory={activityObj.interest?.title}
                  userName={`${activityObj.userObject?.givenName} ${activityObj.userObject?.familyName}`}
                  title={activityObj.name}
                  text={activityObj.description}
                  currency={activityObj.currency || ""}
                  price={activityObj.price
                    ? parseFloat(
                        activityObj.price
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "FREE"}
                  day={sDay}
                  month={sMonth}
                  privacy={activityObj.privacy}
                  startTime={`${sHour}:${sMinute}`}
                  endTime={`${eHour}:${eMinute}`}
                  participants={activityObj.numberOfParticipants}
                  rsvped={activityObj.activityAttendance?.items?.filter(obj=>obj.status === "CONFIRMED").length}
                  address={activityObj.address}
                  userAvatar={activityObj?.userObject?.images?.items?.[0]?.url}
                  userId={activityObj?.userObject?.id}
                  attendees={activityObj.activityAttendance?.items?.filter(obj=>obj.status === "CONFIRMED")?.slice(0, 5)}
                  remainingDays={timestampdifference(new Date(), startTimestamp)}
                />
              </Box>
            );
          })}
    </>
  );
};

export default ActivityCardContainer;
